import React from 'react'
import { useState, useContext , useEffect} from 'react'
import { StatusContext } from './StatusContext'
import Loan_02 from './Loan_02';
import './css/Step.css';
import Loan_03 from './Loan_03';

export default function Template_2() {

  const {step_status,setStepStatus}= useContext(StatusContext)  

  const handleClick3 = ()=>{
    setStepStatus(3)
  }

  const handleClickBack3 = ()=>{
    setStepStatus(1)
    console.log(step_status)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (

    <div>
      
       <Loan_02 />
       <Loan_03 />
       <br/><button className="step_back" onClick={handleClickBack3}>上一頁</button>&nbsp;&nbsp;<button className="step" onClick={handleClick3}>下一步</button>
    </div>
  )
  }
