import React from 'react'
import { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';

export default function Pdf_div() {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
  
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }
  
    return (
      <div className='div.pdf '>
        <Document file="https://zh22rbmsign.rbmoney.hk/pdf/001.pdf"  scale="4.1666" onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page scale={96/72} key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
        </Document>
      </div>
    );
}

