import React from 'react'
import { useState, useContext , useEffect } from 'react'
import { StatusContext } from './StatusContext'
import { UserContext } from './context/UserContext'
import Pdf_div from './Pdf_div'

import Loan_01 from './Loan_01'
import './css/Step.css';

export default function Template_1() {
    



  const {step_status,setStepStatus}= useContext(StatusContext)  
  const {user,userInfo} = useContext(UserContext);
  const {cust_detial,setCust_detial}= useContext(StatusContext)  

  const [isSubscribed, setIsSubscribed] = useState(false);
  const {isLoaded,setIsLoaded}= useContext(StatusContext)  


//console.log (isLoaded)
  

    const handleClick2 = ()=>{
      setStepStatus(4)
      //console.log(step_status)
    }

    const handleClickBack2 = ()=>{
      setStepStatus(0)
      //console.log(step_status)
    }

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

 const handleChange = event => {
    /*
    if (event.target.checked) {
      console.log('✅ Checkbox is checked');
    } else {
      console.log('⛔️ Checkbox is NOT checked');
    }
    */
    setIsSubscribed(current => !current);
  };
  
console.log (cust_detial.load )
    return (
<>
      {cust_detial.load ==null  &&  <div>
        <p><img width="30%" src={process.env.PUBLIC_URL+"images/loading.gif"} /></p>
        加載中.請稍等</div>}
        
      {cust_detial.load == 3 &&  <div>FAIL!</div>}

      {cust_detial.load == 1 &&  

  
<div>
  
    <Pdf_div />

    

       
        <input
          type="checkbox"
          value={isSubscribed}
          onChange={handleChange}
          id="subscribe"
          name="subscribe"
          className='checkbox_x2'
        /> 
        
        <span className='blue_1'>確認以上內容正確</span>
        <br/>
      
      <br/><button className="step_back" onClick={handleClickBack2}>上一頁</button>&nbsp;&nbsp;
      {
      isSubscribed 
      ? <button className="step" onClick={handleClick2}>下一步</button>
      : <button className="step_disabled" disabled onClick={handleClick2}>未確認</button>
      }
        
      </div>
}
      </>
    )
}
