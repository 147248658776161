import React from 'react'
import { useState, useContext , useEffect } from 'react'
import { StatusContext } from './StatusContext'
import './css/Step.css';
import Loan_04 from './Loan_04';

export default function Template_3() {

  const {step_status,setStepStatus}= useContext(StatusContext)  

  const handleClick4 = ()=>{
    setStepStatus(4)
  }

  const handleClickBack4 = ()=>{
    setStepStatus(2)
    console.log(step_status)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (

    <div>
      
       <Loan_04 />
       <br/><button className="step_back" onClick={handleClickBack4}>上一頁</button>&nbsp;&nbsp;<button className="step" onClick={handleClick4}>下一步</button>
    </div>
  )
  }
